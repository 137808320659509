<template>
  <va-form :id="id" :item="item">
    <template>
      <va-select-input
        source="program_id"
        reference="program"
        label="Program"
      ></va-select-input>
      <va-select-input
        source="party_id"
        reference="party"
        label="Party"
        v-if="this.$store.state.auth.user.role != 'client'"
      ></va-select-input>
      <va-number-input source="mintage"></va-number-input>
      <va-text-input source="po_ref_id"></va-text-input>
      <va-date-input source="product_receive_date"></va-date-input>
    </template>
    <template>
      <va-save-button></va-save-button>
    </template>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item"],
  data: () => ({}),
};
</script>
