<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <bankAccount-show v-if="show" :item="item"></bankAccount-show>
      <bankAccount-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></bankAccount-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon">
      <va-list
        ref="list"
        disable-export
        disable-create-redirect
        @action="onAction"
      >
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "name"],
  data() {
    return {
      fields: [
        { source: "id", sortable: true },
        { source: "name", sortable: true },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
