<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <application-show v-if="show" :item="item"></application-show>
      <application-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></application-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon">
      <va-list
        ref="list"
        disable-create-redirect
        @action="onAction"
        disable-export
        :filters="filters"
      >
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        "parent_id",
        {
          source: "fee_currency_id",
          type: "select",
          label: "Fee Currency",
          attributes: {
            optionText: "name",
            reference: "currency",
          },
        },
      ],
      fields: [
        { source: "id", link: "show" },
        "name",
        { source: "parent_id", link: "show" },
        {
          source: "fee_currency_id",
          type: "reference",
          label: "Fee Currency",
          attributes: {
            reference: "currency",
            fetch: true,
          },
        },
        "fee",
        { source: "created_at", type: "date", sortable: true },
        { source: "updated_at", type: "date", sortable: true },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
