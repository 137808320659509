<template>
  <va-edit-layout>
    <program-form :id="id" :item="item">
      <!-- VA inputs component -->
    </program-form>
  </va-edit-layout>
</template>

<script>
export default {
  props: ["id", "item"],
};
</script>
