<template>
  <va-form :id="id" :item="item">
    <!-- VA inputs component -->
    <template>
      <va-text-input source="name"></va-text-input>
      <va-select-input
        source="parent_id"
        model="parent_id"
        reference="application"
      ></va-select-input>
      <va-select-input
        source="fee_currency_id"
        model="fee_currency_id"
        reference="currency"
        label="Fee Currency"
      ></va-select-input>
      <va-number-input source="fee"></va-number-input>
    </template>
    <template>
      <va-save-button></va-save-button>
    </template>
  </va-form>
</template>

<script>
export default {
  props: ["id", "title", "item"],
  data: () => ({}),
};
</script>
