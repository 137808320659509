/* eslint-disable no-unused-vars */

export default (i18n, admin) => [
  {
    icon: "mdi-view-dashboard",
    text: i18n.t("menu.dashboard"),
    link: "/",
  },
  { divider: true },
  admin.getResourceLink("application"),
  admin.getResourceLink("program"),
  admin.getResourceLink("mintage"),
  admin.getResourceLink("payment"),
  { divider: true },
  admin.getResourceLink("report_event"),
  admin.getResourceLink("report_program"),
  { divider: true },
  admin.getResourceLink("currency"),
  admin.getResourceLink("conversion_rate"),
  admin.getResourceLink("party"),
  { divider: true },
  admin.getResourceLink("bank_account"),
  admin.getResourceLink("payment_status"),
  admin.getResourceLink("payment_type"),
  admin.getResourceLink("program_status"),
  { divider: true },
];
