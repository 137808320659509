<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-select-input
      source="program_id"
      reference="program"
      label="Program"
    ></va-select-input>
    <va-select-input
      source="application_id"
      reference="application"
      label="Application"
    ></va-select-input>
    <va-select-input
      source="payment_type_id"
      reference="payment_type"
      label="Payment Type"
    ></va-select-input>
    <va-text-input source="description"></va-text-input>
    <va-date-input source="transaction_date"></va-date-input>
    <va-date-input source="due_date"></va-date-input>
    <va-select-input
      source="payment_status_id"
      reference="payment_status"
      label="Payment Status"
    ></va-select-input>
    <va-select-input
      source="account_in_id"
      reference="bank_account"
      label="Account In"
    ></va-select-input>
    <va-select-input
      source="account_out_id"
      reference="bank_account"
      label="Account Out"
    ></va-select-input>
    <va-select-input
      source="currency_id"
      reference="currency"
      label="Currency"
    ></va-select-input>
    <va-number-input source="amount"></va-number-input>
    <va-number-input
      source="exchange_rate"
      label="Exchange Rate to USD"
    ></va-number-input>
    <va-number-input
      source="bank_charge"
      label="Bank Charge (USD)"
    ></va-number-input>
    <va-text-input source="transaction_id"></va-text-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item"],
};
</script>
