<template>
  <va-show :item="item">
    <p>
      <span class="v-label theme--light">Program</span>
      <br />
      <va-reference-field
        source="program_id"
        reference="program"
        label="Program"
        fetch="true"
      ></va-reference-field>
    </p>
    <p>
      <span class="v-label theme--light">Application Id</span>
      <br />
      <va-reference-field
        source="application_id"
        reference="application"
      ></va-reference-field>
    </p>
    <p>
      <span class="v-label theme--light">Payment Type</span>
      <br />
      <va-reference-field
        source="payment_type_id"
        reference="payment_type"
        label="Payment Type"
        fetch="true"
      ></va-reference-field>
    </p>
    <va-field source="description"></va-field>
    <p>
      <span class="v-label theme--light">Payment Status</span>
      <br />
      <va-reference-field
        source="payment_status_id"
        reference="payment_status"
        label="Payment Status"
        fetch
      ></va-reference-field>
    </p>
  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
