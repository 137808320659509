export default [
  {
    name: "application",
    icon: "mdi-application-edit-outline",
    label: "name",
    except: ["delete"],
    permissions: ["superadmin", "admin"],
  },
  {
    name: "bank_account",
    icon: "mdi-bank",
    label: "name",
    routes: ["list"],
    except: ["delete"],
    permissions: ["superadmin", "admin"],
  },
  {
    name: "currency",
    icon: "mdi-currency-usd",
    label: "code",
    routes: ["list"],
    except: ["delete"],
    permissions: ["superadmin", "admin"],
  },
  {
    name: "conversion_rate",
    icon: "mdi-swap-horizontal-circle-outline",
    routes: ["list"],
    except: ["delete"],
    permissions: ["superadmin", "admin"],
  },
  {
    name: "mintage",
    icon: "mdi-hand-coin",
    label: "po_ref_id",
    except: ["delete"],
    permissions: ["superadmin", "admin", "client"],
  },
  {
    name: "party",
    icon: "mdi-account-group",
    label: "name",
    routes: ["list"],
    except: ["delete"],
    permissions: ["superadmin", "admin"],
  },
  {
    name: "payment",
    icon: "mdi-cash-check",
    label: "name",
    except: ["delete"],
    permissions: ["superadmin", "admin"],
  },
  {
    name: "payment_type",
    label: "name",
    icon: "mdi-format-list-bulleted-type",
    routes: ["list"],
    except: ["delete"],
    permissions: ["superadmin"],
  },
  {
    name: "payment_status",
    label: "name",
    icon: "mdi-clipboard-flow-outline",
    routes: ["list"],
    except: ["delete"],
    permissions: ["superadmin"],
  },
  {
    name: "program",
    icon: "mdi-newspaper-variant-multiple",
    label: "name",
    except: ["delete"],
    permissions: [
      "superadmin",
      "admin",
      { name: "client", actions: ["list", "show"] },
    ],
  },
  {
    name: "program_status",
    label: "name",
    icon: "mdi-file-table-outline",
    routes: ["list"],
    except: ["delete"],
    permissions: ["superadmin"],
  },
  {
    name: "report_event",
    icon: "mdi-clipboard-clock-outline",
    label: "name",
    except: ["delete", "create", "edit", "show"],
    permissions: ["superadmin", "admin"],
  },
  {
    name: "report_program",
    icon: "mdi-clipboard-check-multiple-outline",
    label: "name",
    except: ["delete", "create", "edit"],
    permissions: ["superadmin", "admin"],
  },

  // manage user
  // {
  //   name: "users",
  //   icon: "mdi-account",
  //   routes: ["list"],
  //   permissions: ["admin"],
  // },
];
