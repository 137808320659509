<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <mintage-show v-if="show" :item="item"></mintage-show>
      <mintage-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></mintage-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon">
      <va-list
        ref="list"
        disable-create-redirect
        disable-export
        @action="onAction"
        :filters="filters"
      >
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>
<script>
export default {
  props: ["resource", "code"],
  data() {
    return {
      filters: [
        {
          source: "program_id",
          type: "autocomplete",
          label: "Program",
          attributes: {
            optionText: "name",
            reference: "program",
          },
        },
        {
          source: "party_id",
          type: "autocomplete",
          label: "Party",
          attributes: {
            optionText: "name",
            reference: "party",
          },
        },
        "po_ref_id",
        {
          source: "product_receive_date_after",
          type: "date",
          label: "Product Receive Date Form",
        },
        {
          source: "product_receive_date_before",
          type: "date",
          label: "Product Receive Date To",
        },
        {
          source: "created_after",
          type: "date",
          label: "Created date from",
        },
        {
          source: "created_before",
          type: "date",
          label: "Created date to",
        },
      ],
      fields: [
        { source: "id", link: "show" },
        {
          source: "program_id",
          type: "reference",
          label: "Program",
          attributes: {
            reference: "program",
            fetch: true,
          },
        },
        {
          source: "party_id",
          type: "reference",
          label: "Party",
          attributes: {
            reference: "party",
            fetch: true,
          },
        },
        "mintage",
        "po_ref_id",
        "product_receive_date",
        { source: "created_at", type: "date", sortable: true },
        { source: "updated_at", type: "date", sortable: true },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
