<template>
  <va-form :id="id" :item="item" disable-redirect @saved="$emit('saved')">
    <va-select-input
      source="from_currency_id"
      model="from_currency_id"
      reference="currency"
      label="From Currency"
    ></va-select-input>
    <va-select-input
      source="to_currency_id"
      model="to_currency_id"
      reference="currency"
      label="To Currency"
    ></va-select-input>
    <va-number-input source="rate"></va-number-input>
    <va-save-button></va-save-button>
  </va-form>
</template>

<script>
export default {
  props: ["id", "item"],
};
</script>
