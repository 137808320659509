<template>
  <va-show-layout>
    <va-show :item="item">
      <v-row justify="center">
        <v-col lg="8">
          <h2>Payment</h2>
          <br />
          <v-data-table
            :headers="paymentTableHeaders"
            :items="payment"
          ></v-data-table>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col lg="8">
          <h2>Mintage</h2>
          <br />
          <v-data-table
            :headers="mintageTableHeaders"
            :items="mintage"
          ></v-data-table>
        </v-col>
      </v-row>
    </va-show>
  </va-show-layout>
</template>

<script>
export default {
  props: ["name", "item"],
  data() {
    return {
      paymentTableHeaders: [
        {
          text: "Amount Total",
          align: "start",
          sortable: false,
          value: "amount_total",
        },
        { text: "Payment Pending In", value: "pending_in" },
        { text: "Payment Pending Out", value: "pending_out" },
        { text: "Bank Charge", value: "bank_charge" },
      ],
      payment: [this.item.payment],
      mintageTableHeaders: [
        { text: "Buyer", value: "buyer" },
        { text: "Seller", value: "seller" },
        { text: "Max", value: "max" },
        { text: "Current Total", value: "current_total" },
        { text: "Remaining", value: "remaining" },
      ],
      mintage: this.item.mintage,
    };
  },
};
</script>
