<template>
  <v-form ref="form" @submit.prevent="validate">
    <div class="text-right text-body-2">
      <!--router-link :to="{ name: 'register' }">
        {{ $t("auth.register") }} &gt;
      </router-link-->
    </div>

    <v-text-field
      :label="$t('auth.email')"
      prepend-icon="mdi-account"
      v-model="form.username"
      required
      :error-messages="errorMessages.email"
      :rules="emailRules"
    ></v-text-field>

    <!--div class="text-right text-body-2">
      <router-link :to="{ name: 'forgot_password' }">
        {{ $t("auth.forgot_password") }} &gt;
      </router-link>
    </div-->

    <v-text-field
      :label="$t('auth.password')"
      prepend-icon="mdi-lock"
      type="password"
      v-model="form.password"
      required
      :rules="passwordRules"
    ></v-text-field>

    <div class="text-center">
      <v-btn
        :loading="loading"
        color="primary"
        large
        type="submit"
        text
        rounded
        @click="validate"
        >{{ $t("auth.sign_in") }}</v-btn
      >
    </div>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      errorMessages: {},
      loading: false,
      valid: true,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [(v) => !!v || "Password is required"],
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
    }),
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;

        try {
          await this.login(this.form);
        } catch (e) {
          if (e.errors) {
            this.errorMessages = e.errors;
            return;
          }

          this.errorMessages = { email: `${this.$t("auth.check_credential")}` };
        } finally {
          this.loading = false;
        }
      } else {
        console.log("Validation Fail");
      }
    },
  },
};
</script>

<style scoped>
#login-page {
  background-color: var(--v-primary-lighten5);
}
</style>
