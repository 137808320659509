<template>
  <va-show :item="item">
    <va-field source="parent_id"></va-field>
    <va-field source="name"></va-field>
    <p>
      <span class="v-label theme--light">Fee Currency</span>
      <br />
      <va-reference-field
        source="fee_currency_id"
        reference="currency"
        fetch="true"
      ></va-reference-field>
    </p>
    <va-field source="fee"></va-field>
  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
