<template>
  <va-form :id="id" :item="item" v-model="model">
    <!-- VA inputs component -->
    <v-row justify="center">
      <v-col lg="8">
        <v-card class="pa-6" outlined>
          <template>
            <v-row>
              <v-col>
                <va-select-input
                  source="application_id"
                  reference="application"
                  label="Application"
                ></va-select-input>
              </v-col>
              <v-col>
                <va-text-input
                  source="name"
                  label="Program Name"
                ></va-text-input>
              </v-col>
              <v-col>
                <va-select-input
                  source="status_id"
                  reference="program_status"
                  label="Status"
                ></va-select-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <va-select-input
                  source="buyer_id"
                  reference="party"
                  label="Buyer"
                ></va-select-input>
              </v-col>
              <v-col>
                <va-select-input
                  source="seller_id"
                  reference="party"
                  label="Seller"
                ></va-select-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <va-number-input source="mintage"></va-number-input>
              </v-col>
              <v-col>
                <va-number-input source="fiji_face_value"></va-number-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <va-select-input
                  @change="currencyChange"
                  source="royalties_payment_currency_id"
                  reference="currency"
                  label="Royalties Payment Currency"
                ></va-select-input>
              </v-col>
              <v-col>
                <va-number-input
                  source="conversion_rate"
                  v-model.lazy.number="conversion_rate"
                ></va-number-input>
              </v-col>
              <v-col>
                <va-number-input
                  source="royalties_per_coin_amount"
                ></va-number-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <va-date-input
                  source="royalties_payment_due_date"
                ></va-date-input>
              </v-col>
              <v-col>
                <va-number-input source="upfront_pc"></va-number-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <va-date-input source="contract_start_date"></va-date-input>
              </v-col>
              <v-col>
                <va-date-input source="contract_end_date"></va-date-input>
              </v-col>
              <v-col>
                <va-date-input source="agreement_sign_date"></va-date-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <va-number-input source="sample_count"></va-number-input>
              </v-col>
              <v-col>
                <va-text-input source="sample_note" multiline></va-text-input>
              </v-col>
              <v-col>
                <va-boolean-input source="is_sample_sent"></va-boolean-input>
              </v-col>
            </v-row>
            <va-text-input source="lpm_skus" multiline></va-text-input>
          </template>
          <template>
            <va-save-button></va-save-button>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </va-form>
</template>

<script>
import axios from "axios";

export default {
  props: ["id", "item"],
  data: () => ({
    conversion_rate: null,
  }),
  methods: {
    getOneResource(resource, value) {
      var apiUrl = process.env.VUE_APP_API_URL;
      let token = localStorage.getItem("jwt_token");

      return axios
        .get(apiUrl + "/" + resource + "/" + value, {
          headers: {
            Authorization: "Bearer " + token, //the token is a variable which holds the token
          },
        })
        .then(function (response) {
          return response.data.rate;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    currencyChange(value) {
      this.getOneResource("conversion_rate", value).then((data) => {
        this.$data.conversion_rate = parseFloat(data); //present on users
        this.model.conversion_rate = parseFloat(data); //store update to form model
      });
    },
  },
};
</script>
