import Vue from "vue";
import VuetifyAdmin from "vuetify-admin";

import "vuetify-admin/src/loader";

import { jsonServerDataProvider } from "vuetify-admin/src/providers";
import { jwtAuthProvider } from "../providers";

import { FetchJson } from "vuetify-admin/src/providers";
import { en } from "vuetify-admin/src/locales";

import router from "@/router";
import routes from "@/router/admin";
import store from "@/store";
import i18n from "@/i18n";
import resources from "@/resources";
// import axios from "axios";

/**
 * Load Admin UI components
 */
Vue.use(VuetifyAdmin);

/**
 * Axios instance
 */
const apiURL = process.env.VUE_APP_API_URL || "http://localhost:8000/v1";

/**
 * Create fetch instance with custom authentication headers
 */
const httpClient = new FetchJson(apiURL, {
  headers: () => {
    let headers = new Headers({
      Accept: "application/json",
    });

    let token = localStorage.getItem("jwt_token");

    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

/**
 * Init admin
 */
export default new VuetifyAdmin({
  router,
  store,
  i18n,
  title: "LPM Fiji App",
  routes,
  locales: {
    en,
  },
  translations: ["en"],
  dataProvider: jsonServerDataProvider(httpClient),
  authProvider: jwtAuthProvider(httpClient, {
    routes: {
      login: "auth",
      logout: "auth/logout",
//      refresh: "auth/refresh",
      user: "auth/me",
    },
  }),
  resources,
  httpClient,
  options: {
    // dateFormat: "short",
    numberFormat: "numeric",
    list: {
      disableGlobalSearch: true,
      disableItemsPerPage: true,
      itemsPerPage: 30,
      itemsPerPageOptions: [30, 60, 90],
    },
  },
});
