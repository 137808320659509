<template>
  <va-show :item="item">
    <template>
      <p>
        <span class="v-label theme--light">Program</span>
        <br />
        <va-reference-field
          source="program_id"
          type="reference"
          reference="program"
          fetch="true"
        ></va-reference-field>
      </p>
      <p>
        <span class="v-label theme--light">Party</span>
        <br />
        <va-reference-field
          source="party_id"
          type="reference"
          reference="party"
          fetch="true"
        ></va-reference-field>
      </p>
      <va-field source="mintage"></va-field>
      <va-field source="po_ref_id"></va-field>
      <va-field source="product_receive_date"></va-field>
    </template>
  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
