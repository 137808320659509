<template>
  <div>
    <va-aside-layout :title="asideTitle">
      <payment-show v-if="show" :item="item"></payment-show>
      <payment-form
        v-else
        :id="id"
        :item="item"
        @saved="onSaved"
      ></payment-form>
    </va-aside-layout>
    <base-material-card :icon="resource.icon">
      <va-list
        ref="list"
        disable-export
        disable-create-redirect
        @action="onAction"
        :filters="filters"
      >
        <va-data-table
          :fields="fields"
          disable-create-redirect
          disable-show-redirect
          disable-edit-redirect
          @item-action="onAction"
        >
        </va-data-table>
      </va-list>
    </base-material-card>
  </div>
</template>

<script>
export default {
  props: ["resource", "code"],
  data() {
    return {
      filters: [
        {
          source: "program_id",
          type: "autocomplete",
          label: "Program",
          attributes: {
            optionText: "name",
            reference: "program",
          },
        },
        "application_id",
        {
          source: "payment_type_id",
          type: "select",
          label: "Payment Type",
          attributes: {
            optionText: "name",
            reference: "payment_type",
          },
        },
        {
          source: "payment_status_id",
          type: "select",
          label: "Payment Status",
          attributes: {
            optionText: "name",
            reference: "payment_status",
          },
        },
        {
          source: "transaction_date_after",
          type: "date",
          label: "Transaction date from",
        },
        {
          source: "transaction_date_before",
          type: "date",
          label: "Transaction date to",
        },
        {
          source: "due_date_after",
          type: "date",
          label: "Due date from",
        },
        {
          source: "due_date_before",
          type: "date",
          label: "Due date to",
        },
      ],
      fields: [
        { source: "id", link: "show" },
        {
          source: "program_id",
          label: "Program",
          type: "reference",
          attributes: {
            reference: "program",
            fetch: true,
          },
        },
        {
          source: "application_id",
          label: "Application",
          type: "reference",
          attributes: {
            reference: "application",
            fetch: true,
          },
        },
        {
          source: "payment_type_id",
          type: "reference",
          label: "Payment Type",
          attributes: {
            reference: "payment_type",
            fetch: true,
          },
        },
        "description",
        { source: "transaction_date", type: "date", sortable: true },
        { source: "due_date", type: "date", sortable: true },
        {
          source: "payment_status_id",
          type: "reference",
          label: "Payment Status",
          attributes: {
            reference: "payment_status",
            fetch: true,
          },
        },
        {
          source: "account_in_id",
          type: "reference",
          label: "Account In",
          attributes: {
            reference: "bank_account",
            fetch: true,
          },
        },
        {
          source: "account_out_id",
          type: "reference",
          label: "Account Out",
          attributes: {
            reference: "bank_account",
            fetch: true,
          },
        },
        {
          source: "currency_id",
          type: "reference",
          label: "Currency",
          attributes: {
            reference: "currency",
            fetch: true,
          },
        },
        "amount",
        "exchange_rate",
        { source: "bank_charge", label: "bank_charge(USD)" },
        "transaction_id",
        { source: "created_at", type: "date", sortable: true },
        { source: "updated_at", type: "date", sortable: true },
      ],
      asideTitle: null,
      id: null,
      item: null,
      show: false,
    };
  },
  methods: {
    async onAction({ action, title, id, item }) {
      this.asideTitle = title;
      this.id = id;
      this.show = action === "show";
      this.item = item;
    },
    onSaved() {
      this.asideTitle = null;
      this.$refs.list.fetchData();
    },
  },
};
</script>
