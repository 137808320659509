<template>
  <va-show :item="item">
    <v-row justify="center">
      <v-col lg="8">
        <v-card class="pa-6" outlined>
          <template>
            <v-row>
              <v-col>
                <va-field
                  source="application_id"
                  reference="application"
                  fetch="true"
                  type="reference"
                  label="Application"
                ></va-field>
              </v-col>
              <v-col>
                <va-field source="name"></va-field>
              </v-col>
              <v-col>
                <va-field
                  source="status_id"
                  reference="program_status"
                  type="reference"
                  fetch="true"
                  label="Status"
                ></va-field>
              </v-col>
              <v-col>
                <va-field
                  source="buyer_id"
                  reference="party"
                  type="reference"
                  fetch="true"
                  label="Buyer"
                ></va-field>
              </v-col>
              <v-col>
                <va-field
                  source="seller_id"
                  reference="party"
                  label="Seller"
                  type="reference"
                  fetch="true"
                ></va-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <va-field source="mintage"></va-field>
              </v-col>
              <v-col>
                <va-field source="fiji_face_value"></va-field>
              </v-col>
              <v-col>
                <va-field source="contract_start_date"></va-field>
              </v-col>
              <v-col>
                <va-field source="contract_end_date"></va-field>
              </v-col>
              <v-col>
                <va-field source="agreement_sign_date"></va-field>
              </v-col> </v-row
            ><v-row>
              <v-col>
                <va-field source="royalties"></va-field>
              </v-col>
              <v-col>
                <va-field
                  source="royalties_payment_currency_id"
                  reference="currency"
                  type="reference"
                  label="Royalties Payment Currency"
                  fetch="true"
                ></va-field>
              </v-col>
              <v-col>
                <va-field
                  source="conversion_rate"
                  v-model.lazy.number="conversion_rate"
                ></va-field>
              </v-col>

              <v-col>
                <va-field source="royalties_per_coin_amount"></va-field>
              </v-col>
              <v-col>
                <va-field source="base_royalties_per_coin_amount"></va-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <va-field source="royalties_payment_due_date"></va-field>
              </v-col>
              <v-col>
                <va-field source="upfront_pc"></va-field>
              </v-col>
              <v-col>
                <va-field source="sample_count"></va-field>
              </v-col>
              <v-col>
                <va-field source="sample_note" multiline></va-field>
              </v-col>
              <v-col>
                <label class="v-label theme--light">Is sample sent</label><br />
                <va-boolean-field
                  source="is_sample_sent"
                  labelTrue="sent already"
                  labelFalse="not yet"
                ></va-boolean-field>
              </v-col>
            </v-row>
            <va-field source="lpm_skus" multiline></va-field>
          </template>
        </v-card>
      </v-col>
    </v-row>
  </va-show>
</template>

<script>
export default {
  props: ["item"],
};
</script>
