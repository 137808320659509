<template>
  <v-card>
    <v-card-text>
      <va-list disable-export :filters="filters">
        <va-data-table :fields="fields"></va-data-table>
      </va-list>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["resource", "title"],
  data() {
    return {
      filters: [
        "application_id",
        {
          source: "name",
          type: "autocomplete",
          label: "Program Name",
          attributes: {
            optionText: "name",
            reference: "program",
          },
        },
        {
          source: "buyer_id",
          type: "select",
          label: "Buyer",
          attributes: {
            optionText: "name",
            reference: "party",
          },
        },
        {
          source: "seller_id",
          type: "select",
          label: "Seller",
          attributes: {
            optionText: "name",
            reference: "party",
          },
        },
        {
          source: "status_id",
          type: "select",
          label: "Status",
          attributes: {
            optionText: "name",
            reference: "program_status",
          },
        },
        {
          source: "created_after",
          type: "date",
          label: "Created date from",
        },
        {
          source: "created_before",
          type: "date",
          label: "Created date to",
        },
      ],
      fields: [
        { source: "id", link: "show" },
        {
          source: "application_id",
          label: "Application",
          type: "reference",
          attributes: {
            reference: "application",
            fetch: true,
          },
        },
        {
          source: "name",
          link: "show",
        },
        {
          source: "buyer_id",
          type: "reference",
          label: "Buyer",
          attributes: {
            reference: "party",
            fetch: true,
          },
        },
        {
          source: "seller_id",
          type: "reference",
          label: "Seller",
          attributes: {
            reference: "party",
            fetch: true,
          },
        },
        "mintage",
        {
          source: "status_id",
          type: "reference",
          label: "Status",
          attributes: {
            reference: "program_status",
            fetch: true,
          },
        },
        { source: "created_at", type: "date", sortable: true },
        { source: "updated_at", type: "date", sortable: true },
      ],
    };
  },
};
</script>
